import React, { useContext } from "react";
import ReactPlayer from 'react-player';
// import { Link } from "react-router-dom";

import LanguageContext from '../../contexts/LanguageContext';
import { LANGUAGE_CONSTANTS } from '../../constants/language';

const TeamArea = () => {
  const { language } = useContext(LanguageContext);
  return (
    <section className="team-area pt-3 pb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="team-img-wrap">
              <ReactPlayer
                  url='https://pixelbrick.s3.ap-southeast-1.amazonaws.com/landing-page-video-unboxing/output.m3u8'
                  className='react-player img-shape'
                  playing
                  controls={false}
                  loop={true}
                  muted={true}
                  playsinline={true}
                  width='90%'
                  height='90%'
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="team-content">
              <div className="section-title mb-25">
                <span className="sub-title">{LANGUAGE_CONSTANTS[language].VIDEO_SUB_TITLE}</span>
                <h2 className="title">
                  {LANGUAGE_CONSTANTS[language].VIDEO_TITLE}
                </h2>
              </div>
              <p>
                {LANGUAGE_CONSTANTS[language].VIDEO_DESCRIPTION}
              </p>
              {/*<Link to="/team" className="btn">*/}
              {/*  Meet Our Team <span></span>*/}
              {/*</Link>*/}
            </div>
          </div>
        </div>
      </div>

      {/*<div className="team-shape-wrap">*/}
      {/*  <img*/}
      {/*    src="/img/images/team_shape01.png"*/}
      {/*    alt=""*/}
      {/*    className="ribbonRotate"*/}
      {/*  />*/}
      {/*  <img*/}
      {/*    src="/img/images/team_shape02.png"*/}
      {/*    alt=""*/}
      {/*    className="float-bob-x"*/}
      {/*  />*/}
      {/*</div>*/}
    </section>
  );
};

export default TeamArea;
